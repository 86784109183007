export const blogs = [
    // { slug: "mayday", title: "👀 快线亏本，服务器续费在即，求捐赠", href: "https://assets.frechat.xyz/mayday.html" },
    { slug: "why-promplate", title: "为什么我要开发 Promplate 🔥🔥🔥", href: "https://muspimerol.site/blog/why-promplate", direct: true },
    { slug: "ddos-sanic", title: "😂 用一种自创的办法解决了最近的DDOS攻击", href: "https://geeknote.net/muspi-merol/posts/2337", direct: true },
    { slug: "ban", title: "🧊 关于封禁", href: "https://assets.frechat.xyz/%E5%85%B3%E4%BA%8E%E5%B0%81%E7%A6%81.html" },
    { slug: "2023-04-24", title: "一篇关于域名被墙、用户量减少的牢骚", href: "https://assets.frechat.xyz/2023-04-24.html" },
    { slug: "fraud-and-resistance", title: "魔高一尺，道高一丈", href: "https://assets.frechat.xyz/%E5%81%B7%E8%B7%91.html" },
    { slug: "domains", title: "最新域名（一段时间没更了）", href: "https://assets.frechat.xyz/%E6%9C%80%E6%96%B0%E5%9F%9F%E5%90%8D.html" },
    { slug: "dogecloud-invite", title: "邀请你注册 高性价比的 dogecloud", href: "https://assets.frechat.xyz/%E6%88%91%E7%9A%84dogecloud%E9%82%80%E8%AF%B7%E9%93%BE%E6%8E%A5.html" },
    { slug: "anti-dos-05-04", title: "我被 DOS 攻击了🤯", href: "https://assets.frechat.xyz/anti-dos-05-04.html" },
];

// export const blogsUpdated = "2023年5月21日"
export const blogsUpdated = "2023年9月15日";

export const docs = [
    // { slug: "understanding-ai-math-logic-and-security", title: "AI的数学逻辑和安全规则", href: "https://assets.frechat.xyz/%E7%94%A8%E6%88%B7%E7%94%BB%E5%83%8F%E5%AF%B9%E4%BA%8E%E5%9B%9E%E7%AD%94%E7%9A%84%E7%B2%BE%E5%87%86%E6%80%A7%E7%9A%84%E5%BD%B1%E5%93%8D", author: 1 },
    { slug: "why-promplate", title: "为什么我要开发 Promplate 🔥🔥🔥", href: "https://muspimerol.site/blog/why-promplate", author: 0 },
    { slug: "tips-01", title: "使用本站前必读", href: "https://assets.frechat.xyz/free-chat-tips-01.html", author: 0 },
    { slug: "tips-02", title: "更多学习资源", href: "https://assets.frechat.xyz/free-chat-tips-02.html", author: 0 },
    { slug: "pwa", title: "如何将本站添加到桌面", href: "https://assets.frechat.xyz/pwa.html", author: 0 },
    { slug: "apk", title: "本站打包的安卓APK安装包", href: "https://assets.frechat.xyz/apk.html", author: 2 },
    { slug: "introducing-endless-chat", title: "Endless Chat 介绍", href: "https://assets.frechat.xyz/Introducing%20Endless%20Chat.html", author: 0 },
];

// export const docsUpdated = "2023年4月28日"
export const docsUpdated = "2023年9月15日";

export const authors = [
    { name: "Muspi Merol", email: "admin@muspimerol.site", donation: "https://assets.frechat.xyz/Snipaste_2023-05-04_14-44-07.png" },
    { name: "以剑哥哥", wechat: "IVU88888888888", donation: "https://assets.frechat.xyz/efc8486afef5bf2555ad534f5fe531f.jpg" },
    { name: "HTL_034", wechat: "system067", email: "zhy01031@163.com", donation: "https://assets.frechat.xyz/62ac9d43e95bbff32eaea796f833111.jpg" },
];
